exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-js": () => import("./../../../src/pages/leaderboard.js" /* webpackChunkName: "component---src-pages-leaderboard-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-traderpass-js": () => import("./../../../src/pages/traderpass.js" /* webpackChunkName: "component---src-pages-traderpass-js" */),
  "component---src-pages-trading-floor-js": () => import("./../../../src/pages/trading-floor.js" /* webpackChunkName: "component---src-pages-trading-floor-js" */)
}

